<template>
    <div class="auth-layout">
        <div class="auth-form">
            个人认证成功
        </div>
    </div>
</template>
<script>
export default{
    name:'index',
    components: {},
    data() {
    return {
    }
  },
  created() {
    
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.auth-layout{padding:60px 0;background:#fafafa;}
.personal-auth{width:1440px;margin:0 auto; display:flex;align-items:center; justify-content: space-between;
    .cate-item{width:120px;height:414px;border-radius: 24px;background: #FFFFFF;display: flex;flex-direction: column;align-items: center;justify-content: center;font-family: Source Han Sans;color: #333333;
        img{ display:block;width: 150px;height: 150px;margin-bottom:20px;}
        .title{height: 28px;line-height: 28px;text-align: center;font-family: Source Han Sans;font-size: 28px;font-weight: bold;margin-bottom:10px;}
        span{font-size: 22px;}
    }
}
</style>